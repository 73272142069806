<template>
  <div class="filters d-flex align-center px-2">
    <v-icon 
      v-if="breakpoint('xs',null)"
      :color="isFiltered ? 'primary' : 'grey'"
      class="icon"
    >
      {{ icons.filter }}
    </v-icon>
    <v-menu
      v-model="menu"
      max-width="320"
      offset-y
      transition="slide-y-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <div 
          class="activator d-flex align-center"
          v-on="on"
        >
          <v-btn
            v-if="!isFiltered"
            text
            plain
            class="mr-2"
          >
            {{ $t('general.filter') }}
          </v-btn>
          <div class="brief mx-2 d-flex align-center scrollable x">
            <v-chip
              v-for="(filter, f) in applied"
              :key="'filter-'+f"
              color="primary"
              outlined
              close
              class="chip flex-shrink-0"
              @click:close="onChange(filter.key, null)"
              v-html="formatText(filter.text)"
            />
          </div>
          <v-btn
            v-show="isFiltered"
            icon
            small
            color="primary"
            @click="onChange(null)"
          >
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
        </div>
      </template>
      <v-sheet
        class="content"
      >
        <v-subheader class="text-overline">{{ $t('general.filter') }}</v-subheader>
        <v-card-text 
          class="scrollable border-x pt-0"
          style="max-height: 60vh;"
        >
          <v-subheader class="text-overline px-0">{{ $t('drivers.personal.title') }}</v-subheader>
          <v-combobox
            v-model="controller.city"
            :items="citiesList"
            :loading="filters.city.loading"
            :disabled="filters.city.disabled"
            :label="$t(filters.city.label)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            :return-object="false"
            dense
            clearable
            chips
            small-chips
            multiple
            hide-details
            outlined
            color="primary"
            class="city-filter filter mb-3"
            @change="(value) => onChange('city', value)"
          />
          <v-combobox
            v-model="controller.neighborhood"
            :items="neighborhoods"
            :loading="filters.neighborhood.loading"
            :disabled="filters.neighborhood.disabled"
            :label="$t(filters.neighborhood.label)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            :return-object="false"
            dense
            clearable
            chips
            small-chips
            multiple
            hide-details
            outlined
            color="primary"
            class="neighborhood-filter filter mb-3"
            @change="(value) => onChange('neighborhood', value)"
          />
          <v-select
            v-model="controller.gender"
            :items="translate(filters.gender.items)"
            :loading="filters.gender.loading"
            :disabled="filters.gender.disabled"
            :label="$t(filters.gender.label)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            clearable
            multiple
            hide-details
            outlined
            chips
            small-chips
            dense
            color="primary"
            class="gender-filter filter mb-3"
            @change="(value) => onChange('gender', value)"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip 
                v-if="index === 0"
                small
              >
                <span>{{ item.text }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text caption"
              >
                (+{{ controller.gender.length - 1 }})
              </span>
            </template>
          </v-select>
          <v-subheader class="text-overline px-0">{{ $t('drivers.vehicle.title') }}</v-subheader>
          <v-autocomplete
            v-model="controller.model"
            :items="filters.model.items"
            :loading="filters.model.loading"
            :disabled="filters.model.disabled"
            :label="$tc(filters.model.label)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            clearable
            multiple
            hide-details
            outlined
            dense
            color="primary"
            class="filter model-filter mb-3"
            @change="(value) => onChange('model', value)"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip 
                v-if="index === 0"
                small
              >
                <span>{{ item }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text caption"
              >
                (+{{ controller.model.length - 1 }})
              </span>
            </template>
          </v-autocomplete>
          <v-select
            v-model="controller.owner"
            :items="translate(filters.owner.items)"
            :loading="filters.owner.loading"
            :disabled="filters.owner.disabled"
            :label="$t(filters.owner.label)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            clearable
            multiple
            hide-details
            outlined
            dense
            color="primary"
            class="owner-filter filter mb-3"
            @change="(value) => onChange('owner', value)"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip 
                v-if="index === 0"
                small
              >
                <span>{{ item.text }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text caption"
              >
                (+{{ controller.owner.length - 1 }})
              </span>
            </template>
          </v-select>
          <v-text-field
            v-model="controller.year"
            :loading="filters.year.loading"
            :disabled="filters.year.disabled"
            :label="$t(filters.year.label)"
            clearable
            hide-details
            outlined
            dense
            color="primary"
            class="year-filter filter mb-3"
            @input="(value) => onChange('year', value)"
          />
          <v-subheader class="text-overline px-0">{{ $t('drivers.job.title') }}</v-subheader>
          <v-text-field
            v-model="controller.job_rating"
            :loading="filters.job_rating.loading"
            :disabled="filters.job_rating.disabled"
            :label="$t(filters.job_rating.label)"
            prefix=">="
            hide-details
            outlined
            dense
            color="primary"
            class="job_rating-filter filter mb-3"
            @input="(value) => onChange('job_rating', value)"
          />
          <v-select
            v-model="controller.journey"
            :items="translate(filters.journey.items)"
            :loading="filters.journey.loading"
            :disabled="filters.journey.disabled"
            :label="$t(filters.journey.label)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            multiple
            clearable
            hide-details
            outlined
            dense
            chips
            small-chips
            color="primary"
            class="journey-filter filter mb-3"
            @change="(value) => onChange('journey', value)"
          />
          <v-autocomplete
            v-model="controller.region"
            :items="regions"
            :loading="filters.region.loading"
            :disabled="filters.region.disabled"
            :label="$t(filters.region.label)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            multiple
            clearable
            hide-details
            outlined
            dense
            chips
            small-chips
            color="primary"
            class="region-filter filter mb-3"
            @change="(value) => onChange('region', value)"
          />
          <v-select
            v-model="controller.special_projects"
            :items="translate(filters.special_projects.items)"
            :loading="filters.special_projects.loading"
            :disabled="filters.special_projects.disabled"
            :label="$t(filters.special_projects.label)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            multiple
            clearable
            hide-details
            outlined
            dense
            chips
            small-chips
            color="primary"
            class="special_projects-filter filter mb-3"
            @change="(value) => onChange('special_projects', value)"
          />
          <v-select
            v-model="controller.fulltime"
            :items="translate(filters.fulltime.items)"
            :loading="filters.fulltime.loading"
            :disabled="filters.fulltime.disabled"
            :label="$t(filters.fulltime.label)"
            menu-props="bottom, offsetY"
            clearable
            multiple
            hide-details
            outlined
            dense
            color="primary"
            class="fulltime-filter filter mb-3"
            @change="(value) => onChange('fulltime', value)"
          />
          <v-select
            v-model="controller.service_model"
            :items="translate(filters.service_model.items)"
            :loading="filters.service_model.loading"
            :disabled="filters.service_model.disabled"
            :label="$t(filters.service_model.label)"
            menu-props="bottom, offsetY"
            clearable
            multiple
            hide-details
            outlined
            dense
            color="primary"
            class="service_model-filter filter mb-3"
            @change="(value) => onChange('service_model', value)"
          />
          <v-subheader class="text-overline px-0">{{ $t('drivers.control.title') }}</v-subheader>
          <v-select
            v-model="controller.rating"
            :items="filters.rating.items"
            :loading="filters.rating.loading"
            :disabled="filters.rating.disabled"
            :label="$tc(filters.rating.label)"
            menu-props="bottom, offsetY"
            clearable
            multiple
            hide-details
            outlined
            dense
            color="primary"
            class="rating-filter filter mb-3"
            @change="(value) => onChange('rating', value)"
          >
            <template v-slot:item="{ item }">
              <v-icon
                v-for="rating in item.value"
                :key="'rating-filter-item-'+rating"
                :color="item.color"
                size="24"
              >
                {{ icons.rating }}
              </v-icon>
            </template>
            <template v-slot:selection="{ item }">
              <v-icon
                :color="item.color"
                size="20"
              >
                {{ icons.rating }}
              </v-icon>
            </template>
          </v-select>
          <v-combobox
            v-model="controller.tags"
            :items="tags"
            :loading="filters.tags.loading"
            :disabled="filters.tags.disabled"
            :label="$tc(filters.tags.label, 0)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            clearable
            multiple
            hide-no-data
            hide-details
            chips
            small-chips
            outlined
            dense
            color="primary"
            class="tags-filter filter mb-3"
            @change="(value) => onChange('tags', value)"
          />
          <v-select
            v-model="controller.app_version"
            :items="filters.app_version.items"
            :loading="filters.app_version.loading"
            :disabled="filters.app_version.disabled"
            :label="$t(filters.app_version.label)"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
            clearable
            hide-details
            outlined
            dense
            color="primary"
            class="app_version-filter filter"
            @change="(value) => onChange('app_version', value)"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            class="btn-confirm"
            @click="menu = !menu"
          >
            {{ $t('general.ok') }}
          </v-btn>
        </v-card-actions>
      </v-sheet>
    </v-menu>
  </div>
</template>

<style>

  .filters .brief {
    max-width: 60vw;
  }
  .filters .brief .chip:not(:last-child) {
    margin-right: 4px;  
  }
  .filters .brief b {
    padding-left: 3px;
  }

</style>

<script>

import { mdiFilterVariant, mdiCheck, mdiStar, mdiClose } from '@mdi/js';
import services from '@/services'

export default {
  props: {
    filters: {
      type: Object,
      default: () => {}
    },
    tags: {
      type: Array,
      default: () => []
    },
    cities: {
      type: Array,
      default: () => []
    },
    neighborhoods: {
      type: Array,
      default: () => []
    },
    regions: {
      type: Array,
      default: () => []
    },
  },  
	data: () => ({
    icons: {
      filter: mdiFilterVariant,
      check: mdiCheck,
      rating: mdiStar,
      close: mdiClose
    },
    menu: false,
    toggle: {
      rating: false,
      tags: false,
      city: false,
      special_projects: false,
      owner: false,
      gender: false,
      model: false,
      app_version: false,
    },
	  controller: {
      rating: null,
      tags: null,
      city: null,
      neighborhood: null,
      special_projects: null,
      model: null,
      year: null,
      owner: null,
      fulltime: null,
      service_model: null,
      journey: null,
      region: null,
      job_rating: null,
      gender: null,
      app_version: null,
    }
	}),
	computed: {
    applied () {
      const filters = this.controller;
      return _.map(_.pickBy(filters, f => !!f && !_.isEmpty(f)), (value, key) => {
        value = _.isArray(value) ? value : [value];
        const text = _.join(_.map(value, v => {
          const text = _.isObject(v) ? v.text : 'items' in this.filters[key] && !!_.find(this.filters[key].items, ['value', v]) ? this.$tc(_.find(this.filters[key].items, ['value', v]).text) : v;
          return text;
        }), ', ')
        return {
          text: this.$tc(this.filters[key].label) + ': *' + text + '*',
          value,
          key
        }
      });
    },
    isFiltered () {
      return _.size(this.applied);
    },
    citiesList () {
      const cities = this.cities;
      const states = _.groupBy(cities, city => {
        return _.last(city.split('/')) || 'UF';
      });
      const list = _.reduce(states, (result, state, header) => {
        result.push({ header }, ..._.map(state.sort(), city => {
          return {
            text: _.first(city.split('/')),
            value: city,
            group: header
          }
        }));
        return result;
      }, []);
      return list;
    }
	},
	watch: {
    filters: {
      immediate: true,
      deep: true,
      handler (filters) {
        const values = _.mapValues(_.cloneDeep(filters), 'value');
        _.each(this.controller, (value, key) => {
          this.controller[key] = values[key];
          // console.log(key, value);
        });
      }
    }
	},
	methods: {
    ...services,

    // clear () {
    //   _.each(this.controller, field => {
    //     field = null;
    //   });
    // },

    onToggle (field) {
      this.toggle = !this.toggle[field];
    },

		onChange (field, value) {
      this.$emit('change', field, value);

      if (field==null) {
        setTimeout(() => {
          this.menu = false;
        }, 500);
      }
    }
	}
}
</script>